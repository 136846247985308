.newChatIndicatorContainer {
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  right: 35px;
  transform: translate(0%, -50%);
}

.newChatIndicatorCenterCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  border-radius: 10px;
  background-color: #4caf50;
  z-index: 1;
}

.newChatIndicatorOuterCircle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 25px;
  width: 25px;
  border-radius: 20px;
  background: radial-gradient(circle, rgba(48, 48, 48, 0.404) 0%, rgba(52, 67, 53, 0) 45%, rgba(76, 175, 80, 1) 100%);
  background-position: center;
  animation-name: radialPulse;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

@keyframes radialPulse {
  0% {
    background-size: 100%;
  }
  50% {
    background-size: 200%;
  }
  100% {
    background-size: 100%;
  }
}
