.joinPageParticipantStripScrollContainer {
  height: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
}

@media (orientation: portrait) {
  .joinPageParticipantStripScrollContainer {
    grid-template-rows: 50% 50%;
    grid-template-columns: 1fr;
  }
}
