.scrollbar {
  overflow-y: scroll;
}

.force-overflow {
  min-height: 450px;
}

.walklistCandidateContainer {
  padding: 0px !important;
}

#wrapper {
  text-align: center;
  margin: auto;
}

#scrollableDiv::-webkit-scrollbar {
  width: 10px;
  background-color: #303030;
}

/* Light theme by default */
/* :root {
  --scrollbar-track-color: #e2e2e2;
  --scrollbar-thumb-color: #b4b4b4;
} */

/* Dark theme */

:root {
  --scrollbar-track-color: #303030;
  --scrollbar-thumb-color: #202020;
}

/**  STYLE 4 */

#scrollableDiv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(150, 150, 150, 0.3);
  background-color: var(--scrollbar-track-color);
}

#scrollableDiv::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb-color);
}
