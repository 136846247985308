.joinAppContainer {
  padding: 20px;
  background: #0d122b;
  height: 100vh;
  display: grid;
  justify-items: center;
  overflow: hidden;
}

.joinAppContentContainer {
  margin: 15px 0px 0px 0px;
  width: 100%;
}
